.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: alverata, sans-serif;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: alverata, sans-serif;
  font-size: 1rem;
  line-height: 36px;
  font-weight: 700;
}

/* TOOLTIP STYLING  START */

.mapboxgl-popup-content {
  width: 120px;

  background: #696c61;
  color: #fff;
  font-family: 'SofiaPro-Medium';
  font-size: 1.2rem;
  font-weight: bold;
}

.mapboxgl-popup-tip {
  border-top-color: #696c61 !important;
}

.tooltip-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.tooltip-stage-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;  
}

.tolltip-wrapper-dot {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin-right: 5px;
  background: #b8ff3e;
}

.tolltip-wrapper-dot.available {
  background: #b8ff3e;
}

.tolltip-wrapper-dot.deposited {
  background: #ffef3e;
}

.tolltip-wrapper-dot.sold {
  background: #ff7b3e;
}

.tooltip-text {
  font-family: Karla, sans-serif;
}

.tooltip-text-stage {
  font-family: Karla, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
.tooltip-text-stage-body {
  font-size: 0.8rem;
  font-weight: lighter;
}

