.marker {
  background-image: url('/images/markers/mapbox-icon.png');
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  visibility: hidden;
}

.marker.food {
  background-image: url('/images/food-selected.png');
}

.marker.hospital {
  background-image: url('/images/hospital-selected.png');
}

.marker.education {
  background-image: url('/images/education-selected.png');
}

.marker.shop {
  background-image: url('/images/shop-selected.png');
}

.marker.parks {
  background-image: url('/images/parks-selected.png');
}

.marker.airports {
  background-image: url('/images/airport-selected.png');
}

.marker.playgrounds {
  background-image: url('/images/beaches-selected.png');
}

.marker.walking-trails {
  background-image: url('/images/walking-trails-selected.png');
}
